import { withRouter } from "react-router-dom";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHLatitudeLongitude(props) {
  const {
    nameLatitude,
    nameLongitude,
    title,
    isRequired = false,
    restAccessCode,
    state,
    setState,
    readOnly,
    name,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <>
      <style>
        {`
          .ch-latlong-input::-webkit-inner-spin-button,
          .ch-latlong-input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .ch-latlong-input {
            -moz-appearance: textfield;
          }
        `}
      </style>

      <div
        className="form-group row mb-3"
        style={{
          display:
            !isRequired &&
            restAccessCode &&
            utils.access.isFieldHidden(
              fieldAccesses,
              restAccessCode + "." + name
            )
              ? "none"
              : "flex",
        }}
      >
        <label
          className={
            "col-md-3 col-form-label" +
            (isRequired ? " font-weight-bold" : "") +
            (state[nameLatitude + "Error"] ||
            state[nameLongitude + "Error"]
              ? " text-danger"
              : "")
          }
        >
          {title}
          {isRequired ? "*" : ""}{" "}
          {readOnly ??
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
            ? "(read only)"
            : ""}
        </label>
        <div className="col-md-9">
          <div className="d-flex">
            <div className="flex-fill">
              <label className="col-form-label">Latitude</label>
              <input
                type="number"
                name={nameLatitude}
                className="form-control ch-latlong-input"
                style={{
                  backgroundColor: readOnly ? "#f8f8f8" : "#ffffff",
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
                readOnly={
                  readOnly ??
                  (restAccessCode &&
                    utils.access.isFieldReadOnly(
                      fieldAccesses,
                      restAccessCode + "." + name,
                      fieldAccessReversePolicy,
                      isSuperUser
                    ))
                }
                value={state[nameLatitude]}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    [nameLatitude]: e.target.value,
                  }));
                }}
              />
              {state[nameLatitude + "Error"] ? (
                <span
                  className="text-danger"
                  style={{ fontSize: "12px" }}
                >
                  {state[nameLatitude + "Error"]}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="flex-fill pl-4">
              <label className="col-form-label">Longitude</label>
              <input
                type="number"
                name={nameLongitude}
                className="form-control ch-latlong-input"
                style={{
                  backgroundColor: readOnly ? "#f8f8f8" : "#ffffff",
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
                readOnly={
                  readOnly ??
                  (restAccessCode &&
                    utils.access.isFieldReadOnly(
                      fieldAccesses,
                      restAccessCode + "." + name,
                      fieldAccessReversePolicy,
                      isSuperUser
                    ))
                }
                value={state[nameLongitude]}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    [nameLongitude]: e.target.value,
                  }));
                }}
              />
              {state[nameLongitude + "Error"] ? (
                <span
                  className="text-danger"
                  style={{ fontSize: "12px" }}
                >
                  {state[nameLongitude + "Error"]}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CHLatitudeLongitude);
