import { useParams, withRouter } from "react-router-dom";
import Page from "components/ubold/organisms/Page";

function DiscountMasterAddEditPage() {
  return (
    <Page title="(DEPRECATED) Discount Master">
      <p>
        <br />
        Untuk create atau edit discount master dapat dilakukan di
        retool dengan cara:
        <br />
        <br />
        <ol>
          <li>
            Navigate to{" "}
            <a href="https://rukita.retool.com/p/create-price-cut">
              https://rukita.retool.com/p/create-price-cut
            </a>
          </li>
          <li>Untuk create baru, klik tombol Create New Discount</li>
          <li>
            Untuk edit, pilih discount yang ingin diedit pada table
          </li>
        </ol>
        <br />
        NB: Jika terdapat kendala dalam pengoperasiannya, dapat
        menghubungi tim product (roberta@rukita.co)
      </p>
    </Page>
  );
}

export default withRouter(DiscountMasterAddEditPage);
