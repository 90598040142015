import { useParams, withRouter } from "react-router-dom";
import Page from "components/ubold/organisms/Page";

function DiscountMasterViewPage() {
  return (
    <Page title="(DEPRECATED) Discount Master View">
      <p>
        <br />
        Untuk melihat discount master dapat dilakukan di retool dengan
        cara:
        <br />
        <br />
        <ol>
          <li>
            Navigate to{" "}
            <a href="https://rukita.retool.com/p/create-price-cut">
              https://rukita.retool.com/p/create-price-cut
            </a>
          </li>
          <li>Pilih discount yang ingin dilihat pada table</li>
        </ol>
        <br />
        NB: Jika terdapat kendala dalam pengoperasiannya, dapat
        menghubungi tim product (roberta@rukita.co)
      </p>
    </Page>
  );
}

export default withRouter(DiscountMasterViewPage);
