import { withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

function CBuildingLandlordViewPage() {
  return (
    <Page title="(DEPRECATED) Landlord">
      <p>
        <br />
        Untuk manage landlord dapat dilakukan di retool dengan cara:
        <br />
        <br />
        <ol>
          <li>
            Navigate to{" "}
            <a href="https://rukita.retool.com/p/landlord-filter-org">
              https://rukita.retool.com/p/landlord-filter-org
            </a>
          </li>
        </ol>
        <br />
        NB: Jika terdapat kendala dalam pengoperasiannya, dapat
        menghubungi{" "}
        <a href="mailto:mega@rukita.co">
          Mega Setiawati (mega@rukita.co)
        </a>
      </p>
    </Page>
  );
}

export default withRouter(CBuildingLandlordViewPage);
