import { useEffect } from "react";

import { Link, useParams, withRouter } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";

import Swal from "sweetalert2";

import TopBar from "../TopBar";
import Sidebar from "../Sidebar2";

import utils from "../../../utils";
import configs from "../../../configs";

import useGlobalState from "../../../state";
import styled from "styled-components";
import Breadcrumb from "../molecules/display/Breadcrumb/Breadcrumb";
import { handleRefreshToken } from "utils/HttpClient";

const Page = (props) => {
  const params = new URLSearchParams(window.location.search);

  const pageType = params.get("__page_type__");
  const childrenProps = props?.children?.props;
  const tableProps = childrenProps?.definitions?.table;

  const [user, setUser] = useGlobalState("user");
  const [, setMenus] = useGlobalState("menus");
  const [, setMenuAccesses] = useGlobalState("menu_accesses");
  const [, setAccesses] = useGlobalState("accesses");
  const [, setFieldAccesses] = useGlobalState("field_accesses");
  const [, setFieldAccessesReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  const { id } = useParams();

  const generateHistoryLinkForEditPage = () => {
    if (id && childrenProps?.definitions?.linkHistory) {
      return childrenProps?.definitions?.linkHistory(id);
    }

    return null;
  };

  const [, setConstantValues] = useGlobalState("constant_values");

  const init = (data) => {
    const sweetAlert = withReactContent(Swal);

    setMenus(data.menus);
    setMenuAccesses(data["menu_accesses"]);
    setAccesses(data.accesses);
    setFieldAccesses(data.field_accesses);
    // better approach to set if the array really exist, not undefined/null
    if (Array.isArray(data?.field_access_reverse_policy)) {
      setFieldAccessesReversePolicy(
        data?.field_access_reverse_policy
      );
    }

    let hasAccess = utils.commons.hasAccess(
      window.location.pathname,
      data["menu_accesses"]
    );

    if (
      !hasAccess &&
      !data["is_super_user"] &&
      !window.location.pathname.startsWith("/u/home") &&
      !(
        window.location.pathname.startsWith("/team") &&
        data["has_team_management_access"]
      )
    ) {
      sweetAlert.fire(
        "Access Denied",
        "You have no access to " + window.location.pathname,
        "error"
      );

      let topAccessibleMenu = utils.store.get("topAccessibleMenu");

      props.history.push(topAccessibleMenu || "/u/home");
    }

    delete data.menu_accesses;
    delete data.menus;
    delete data.accesses;
    delete data.field_accesses;

    setInterval(() => {
      let twoColSideNavItems = $("#two-col-sidenav-main .nav-link");
      let sideSubMenus = $(".twocolumn-menu-item");

      twoColSideNavItems.on("click", function (e) {
        let target = $($(this).attr("href"));

        e.preventDefault();

        if (target.length) {
          twoColSideNavItems.removeClass("active");
          $(this).addClass("active");

          sideSubMenus.removeClass("d-block");
          target.addClass("d-block");

          // showing full sidebar if menu item is clicked
          $.LayoutThemeApp.leftSidebar.changeSize("default");

          return false;
        }
        return true;
      });
    }, 500);

    setUser(data);
  };

  const generateDetailFieldId = () => {
    // Use id from first column definitions by default if titleDetailFieldId not defined
    if (
      tableProps?.columns?.length > 0 &&
      !props?.titleDetailFieldId
    ) {
      return tableProps?.columns[0]?.id;
    }

    return null;
  };

  useEffect(() => {
    const t = utils.store.get(configs.t_key);

    if (!t) {
      const nextPath = window.location.pathname;
      props.history.push("/login?next=" + nextPath);
    } else {
      const cacheKey = "initData";

      if (user === null) {
        utils.httpClient.get(
          configs.apiUrl + "init/",
          (data) => {
            if (
              window.location.pathname === "/" ||
              window.location.pathname === ""
            ) {
              props.history.push("/u/home");
            }

            init(data.data);
          },
          () => {
            const nextPath = window.location.pathname;

            utils.store.remove("t");

            props.history.push("/login?next=" + nextPath);
          },
          false,
          cacheKey
        );

        utils.httpClient.get(
          configs.apiUrl + "init_constant_values/",
          (data) => {
            setConstantValues(data.data);
          }
        );

        setInterval(() => {
          handleRefreshToken(utils.store);
        }, 1000 * 290); // 290 seconds
      }
    }

    $(function () {
      $.App.init2();
    });
  }, []);

  const convertUrlsToLinks = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with anchor tags
    return text.replace(
      urlRegex,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );
  };

  // Component usage
  const NotificationText = ({ text }) => {
    return (
      <p
        style={{
          fontSize: 16,
          color: "red",
          fontWeight: 500,
          lineHeight: "24px",
          fontStyle: "bold",
        }}
        dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(text) }}
      />
    );
  };

  return (
    <div>
      <div id="wrapper">
        <TopBar />
        {!props?.isProfilePage && <Sidebar />}

        <div
          className="content-page"
          style={{
            minHeight: "unset",
            marginLeft:
              pageType && pageType === "no_frame" ? "0px" : "290px",
            marginTop:
              pageType && pageType === "no_frame" ? "0px" : "70px",
          }}
        >
          <div className="content">
            <div
              className={`container-${
                props?.container ? props?.container : "fluid"
              }`}
            >
              {(childrenProps?.isUseNewFilter ||
                tableProps?.isUseNewFilter) && (
                <div className="row">
                  <div className="col-12">
                    <Breadcrumb
                      className="mt-3"
                      pageDetailTitleId={generateDetailFieldId()}
                      state={
                        props?.children?.props?.state
                          ? props?.children?.props?.state
                          : null
                      }
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="page-title-box">
                      <TitleContainer>{props.title}</TitleContainer>
                      {props?.subTitle && (
                        <NotificationText text={props?.subTitle} />
                      )}
                    </div>
                    {(childrenProps?.isBuildingDashboard ||
                      props?.breadcrumb?.alignWithPageTitle) && (
                      <div className="row">
                        <div className="col-12">
                          <Breadcrumb
                            className="mt-3"
                            pageDetailTitleId={generateDetailFieldId()}
                            state={
                              childrenProps?.state
                                ? childrenProps?.state
                                : null
                            }
                          />
                        </div>
                      </div>
                    )}

                    {generateHistoryLinkForEditPage() && (
                      <div className="view-history d-flex align-items-center">
                        <span
                          style={{
                            color: "#35b0a7",
                            fontSize: 12,
                            fontWeight: 500,
                            marginRight: "10px",
                          }}
                        >
                          <Link to={generateHistoryLinkForEditPage()}>
                            View History
                          </Link>
                        </span>

                        <i
                          className="fa fa-chevron-right"
                          style={{ color: "#35b0a7", fontSize: 12 }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Page);

const TitleContainer = styled.div`
  font-size: 20px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 28px;
  font-style: normal;
  color: #222222;
  padding-top: 16px;
`;
