/**
 * All Fields inside this tab is deprecated
 * please change to latest update based on
 * order management confluence
 */
import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import moment from "moment";
import { useEffect } from "react";

function OrderAdditionalDiscounts(props) {
  const { definitions, tab, state, setState } = props;
  let { id } = useParams();
  const doneStatus = [5, 6, 10];

  const afterClearDate = ({ index, column }) => {
    const dateKey =
      column.name && column.name === "start_date"
        ? "start_date"
        : "end_date";
    const tempPromoDateRanges = {
      ...state["promoDateRanges"],
    };
    const tempDiscountDateRanges = {
      ...state["discountDateRanges"],
    };
    if (tempPromoDateRanges["selectedDate"][index]) {
      tempPromoDateRanges["selectedDate"][index][dateKey] = "";
    }
    if (tempDiscountDateRanges["selectedDate"][index]) {
      tempDiscountDateRanges["selectedDate"][index][dateKey] = "";
    }
    setState((prevState) => ({
      ...prevState,
      discountDateRanges: tempDiscountDateRanges,
      promoDateRanges: tempPromoDateRanges,
    }));
  };

  const endDateAfterChange = ({ carryOverState, index }) => {
    const momentStartDate = moment(carryOverState[index]?.start_date);
    const momentEndDate = moment(carryOverState[index]?.end_date);
    const isEndDateAfterStartDate =
      momentEndDate.isAfter(momentStartDate);
    const tempCarryOverState = [...carryOverState];
    if (isEndDateAfterStartDate) {
      if (state[tab?.id + "InfoMessage"]) {
        const tempState = { ...state };
        delete tempState[tab?.id + "InfoMessage"];
        setState({
          ...tempState,
          [tab?.id]: tempCarryOverState,
        });
      }
      return;
    }
    tempCarryOverState[index].end_date = "";
    setState((prevState) => ({
      ...prevState,
      [tab?.id]: tempCarryOverState,
      [tab.id + "InfoMessage"]:
        "Please input end date after start date.",
    }));
  };

  const startDateAfterChange = ({ carryOverState, index }) => {
    if (!carryOverState[index]?.end_date) return;
    const momentStartDate = moment(carryOverState[index]?.start_date);
    const momentEndDate = moment(carryOverState[index]?.end_date);
    const tempCarryOverState = [...carryOverState];
    if (!momentStartDate.isAfter(momentEndDate)) return;
    tempCarryOverState[index].end_date = "";
    setState((prevState) => ({
      ...prevState,
      [tab?.id]: tempCarryOverState,
      [tab.id + "InfoMessage"]:
        "Please input end date after start date.",
    }));
  };

  const generateDateReadOnly = ({ index, column }) => {
    if (id) {
      return state?.original___t_discount_orders
        ? state?.original___t_discount_orders[index]?.[column.name]
        : false;
    }

    if (state?.t_discount_orders[index]?.one_time) {
      return column?.name === "end_date";
    }

    return (
      state?.t_discount_orders[index]
        ?.dicount_promo_minimum_monthly_commitment > 1 ?? false
    );
  };

  const generateMonthPickerExcludedDateRange = ({ index }) => {
    const category = state?.t_discount_orders[index]?.category;

    // Only apply excluded dates for regular discounts (category 1)
    // Return empty array for vouchers and other types
    if (category !== 1) {
      return [];
    }

    const isCategoryPromo = category === 2;

    // Safety check for undefined state
    if (
      !state?.[
        isCategoryPromo ? "promoDateRanges" : "discountDateRanges"
      ]?.selectedDate
    ) {
      return [];
    }

    const tempState =
      state[
        isCategoryPromo ? "promoDateRanges" : "discountDateRanges"
      ]?.selectedDate || [];

    const excludedDate = tempState
      .filter((range) => range?.from && range?.to)
      .map((range) => {
        try {
          const start = moment(range.from).startOf("month");
          const end = moment(range.to).endOf("month");

          if (
            start.isValid() &&
            end.isValid() &&
            end.isSameOrAfter(start)
          ) {
            return {
              start: start.toDate(),
              end: end.toDate(),
            };
          }
          return null;
        } catch (e) {
          return null;
        }
      })
      .filter(Boolean);

    return excludedDate;
  };

  const generateOneTimeDateForReactDatePicker = (isOneTime, date) => {
    if (isOneTime) return new Date(date);
    return date;
  };

  const getDisabledDate = ({ index, type, column }) => {
    const isOneTime =
      state?.t_discount_orders[index]?.one_time ?? false;
    const isCategoryPromo =
      state?.t_discount_orders[index]?.category === 2;

    if (!type) {
      return generateOneTimeDateForReactDatePicker(
        isOneTime,
        state?.[
          isCategoryPromo ? "promoDateRanges" : "discountDateRanges"
        ]?.selectedDate
      );
    }

    if (
      column.name === "end_date" &&
      isCategoryPromo &&
      state?.t_discount_orders[index]
        ?.dicount_promo_minimum_monthly_commitment > 1
    ) {
      if (type === "minDate") {
        return generateOneTimeDateForReactDatePicker(
          isOneTime,
          state?.["promoDateRanges"]?.availableRange[index]?.end_date
        );
      } else {
        return generateOneTimeDateForReactDatePicker(
          isOneTime,
          state["checkout_date"]
        );
      }
    } else {
      if (type === "minDate") {
        return generateOneTimeDateForReactDatePicker(
          isOneTime,
          state?.[
            isCategoryPromo ? "promoDateRanges" : "discountDateRanges"
          ]?.availableRange[index]?.start_date
        );
      } else {
        return generateOneTimeDateForReactDatePicker(
          isOneTime,
          state?.[
            isCategoryPromo ? "promoDateRanges" : "discountDateRanges"
          ]?.availableRange[index]?.end_date
        );
      }
    }
  };

  const getOneTimeStatus = ({ index }) => {
    const tempAdditionalDiscounts = [...state.t_discount_orders];
    return tempAdditionalDiscounts[index]?.one_time ?? false;
  };

  const checkSelectedDate = (
    selectedDate,
    lastPaymentToLandlordDate,
    checkinDate
  ) => {
    if (state?.last_payment_to_landlord) {
      return moment(selectedDate).isSameOrAfter(
        lastPaymentToLandlordDate
      );
    }
    return moment(selectedDate).isSameOrAfter(checkinDate);
  };

  const handleOnChangeMonthPicker = ({
    dataValue,
    column,
    index,
  }) => {
    const isCategoryPromo =
      state?.t_discount_orders[index]?.category === 2;
    const tempPromoDateRanges = {
      ...state.promoDateRanges,
    };
    const tempDiscountDateRanges = {
      ...state.discountDateRanges,
    };
    const selectedDate = moment(dataValue)
      .startOf("month")
      .format("Y-M-D");
    const checkinDate = moment(state?.checkin_date).format("Y-M-D");
    const today = moment().format("Y-M-D");
    const lastPaymentToLandlordDate = moment(
      state?.last_payment_to_landlord
    ).format("Y-M-D");
    const isLastPaymentLandlordAfterCheckin =
      state?.last_payment_to_landlord &&
      moment(lastPaymentToLandlordDate).isAfter(checkinDate);
    const isCheckinAfterToday =
      moment(checkinDate).isSameOrAfter(today);
    const selectedMonth = moment(dataValue).format("YYYY-MM");
    const startDate = checkSelectedDate(
      selectedDate,
      lastPaymentToLandlordDate,
      checkinDate
    )
      ? selectedDate
      : isLastPaymentLandlordAfterCheckin
      ? lastPaymentToLandlordDate
      : isCheckinAfterToday
      ? checkinDate
      : today;

    const checkoutDate = moment(state?.checkout_date) ?? "";
    const endMonthDate = moment(dataValue).endOf("month");
    /**
     * @endDate below need to check if the order have a checkout date.
     * if it is, then it should check whether the checkout date is in the same month
     * and before the end of period of one time discount
     */
    const endDate =
      state?.checkout_date &&
      checkoutDate.isSame(endMonthDate, "month") &&
      !checkoutDate.isAfter(endMonthDate)
        ? checkoutDate.format("Y-M-D")
        : endMonthDate.format("Y-M-D");
    const tempState = [...state[tab?.id]].map((object, i) => {
      if (i === index) {
        return {
          ...object,
          [column.name]: selectedMonth ? selectedMonth : null,
        };
      } else {
        return object;
      }
    });

    tempState[index].start_date = startDate;
    tempState[index].end_date = endDate;

    if (isCategoryPromo) {
      tempPromoDateRanges.selectedDate[index] = {
        from: startDate,
        to: endDate,
      };
    } else {
      tempDiscountDateRanges.selectedDate[index] = {
        from: startDate,
        to: endDate,
      };
    }
    setState((prevState) => ({
      ...prevState,
      promoDateRanges: tempPromoDateRanges,
      discountDateRanges: tempDiscountDateRanges,
      [tab?.id]: tempState,
    }));
  };

  useEffect(() => {
    if (state.t_discount_ordersColumns) {
      const tempDiscountOrderColumn = [
        ...state.t_discount_ordersColumns,
      ];
      const endDateIndex = tempDiscountOrderColumn.findIndex(
        (item) => item.name === "end_date"
      );
      tempDiscountOrderColumn[endDateIndex].isRequired =
        state?.checkout_date || state?.subscription_type === 2;
      setState((prevState) => ({
        ...prevState,
        t_discount_ordersColumns: tempDiscountOrderColumn,
      }));
    }
    /**
     * add id as dependency since for duplicate case, the useEffect is not triggered again.
     * therefore add id as dependency since in duplicate, it will delete property ids
     */
  }, [state.checkout_date, state.subscription_type, state.id]);

  useEffect(() => {
    if (id) return;
    let infoMessage = "";
    if (!state?.checkin_date || !state?.building || !state?.tenant) {
      infoMessage =
        "Before adding additional discount data, please fill tenant, building and checkin date.";
    }
    setState((prevState) => ({
      ...prevState,
      [tab.id + "InfoMessage"]: infoMessage,
    }));
  }, [state?.building, state.checkin_date, state?.tenant]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="discount_order"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab?.id}
        readOnly={
          (id && state.subscription_type === 2) ||
          (id && doneStatus.includes(state?.original___order_status))
        }
        disableActionBtn={
          !state?.checkin_date || !state?.building || !state?.tenant
        }
        restPath="order_new/discount_order/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        infoMessageStandAlone={!id}
        index={1}
        isEligibleForDiscount={
          state?.building_object?.eligible_for_discount ??
          state.eligible_for_discount
        }
        additionalStateInAddNewRow={{
          monthly_commitment: state?.monthly_commitment,
          building: state?.building,
          selected_date: moment().format("Y-M-D"),
          tenant: state?.tenant,
        }}
        afterDeleteRow={({ index }) => {
          const tempPromoDateRanges = { ...state.promoDateRanges };
          const tempDiscountDateRanges = {
            ...state?.discountDateRanges,
          };
          tempPromoDateRanges?.availableRange?.splice(index, 1);
          tempPromoDateRanges?.selectedDate.splice(index, 1);
          tempDiscountDateRanges?.availableRange?.splice(index, 1);
          tempDiscountDateRanges?.selectedDate.splice(index, 1);
          setState((prevState) => ({
            ...prevState,
            promoDateRanges: tempPromoDateRanges,
            discountDateRanges: tempDiscountDateRanges,
          }));
        }}
        columns={[
          {
            name: "category",
            type: "select",
            title: "Category",
            data: [
              { label: "Discount", value: 1 },
              {
                label: "Promo",
                value: 2,
                disabled: id && state["subscription_type"] === 2,
              },
              { label: "Discount Voucher", value: 3 },
              { label: "Apology Voucher", value: 4 },
            ],
            default: 1,
            disabledAtEdit: true,
            isClearable: false,
            afterChange: ({ index, tempState }) => {
              const tempAdditionalDiscounts = [...tempState];
              delete tempAdditionalDiscounts[index]?.one_time;
              delete tempAdditionalDiscounts[index]?.start_date;
              delete tempAdditionalDiscounts[index]?.end_date;
              delete tempAdditionalDiscounts[index]?.discount;
              setState((prevState) => ({
                ...prevState,
                t_discount_orders: tempAdditionalDiscounts,
              }));
            },
          },
          {
            name: "discount",
            type: "async_select",
            title: "Discount/Promo Name",
            disabledAtEdit: true,
            isRequired: true,
            dependsOn: [
              "category",
              "monthly_commitment",
              "building",
              "selected_date",
              "tenant",
            ],
            dependsOnPrefix: ["", "", "_id", "", "_id"],
            width: 300,
            data: "promotion/discount_master/?ordering=-id&__type__=select_entries&is_sop_filter=1",
            additionalParamsWhenFlagIsAdd: ["active=1"],
            isClearable: false,
            itemsExtractor: (row) => {
              return {
                label: row["name"],
                value: row["id"],
                start_date: row["start_date"],
                end_date: row["end_date"],
                minimum_monthly_commitment:
                  row["minimum_monthly_commitment"],
                one_time: row["one_time"],
              };
            },
            afterChange: ({ dataValue, index, tempState }) => {
              const tempAdditionalDiscounts = [...tempState];
              const momentCheckinDate = moment(state?.checkin_date);
              const momentLastPaymentToLandlord = moment(
                state?.last_payment_to_landlord
              );
              const isLastPaymentLandlordAfterCheckin =
                state?.last_payment_to_landlord &&
                momentLastPaymentToLandlord.isAfter(
                  momentCheckinDate
                );
              tempAdditionalDiscounts[index]["one_time"] =
                dataValue?.one_time;
              tempAdditionalDiscounts[index][
                "dicount_promo_minimum_monthly_commitment"
              ] = dataValue.minimum_monthly_commitment;
              const tempPromoDateRanges = {
                ...state.promoDateRanges,
              };
              if (!tempPromoDateRanges.availableRange) {
                tempPromoDateRanges.availableRange = [];
              }
              const tempDiscountDateRanges = {
                ...state?.discountDateRanges,
              };
              if (!tempDiscountDateRanges.availableRange) {
                tempDiscountDateRanges.availableRange = [];
              }
              const isCategoryPromo = tempState[index].category === 2;
              if (
                dataValue?.minimum_monthly_commitment > 1 &&
                Number(state?.monthly_commitment) > 1
              ) {
                const startDate = momentCheckinDate.format("Y-M-D");
                const endDate = moment(startDate)
                  .add(
                    Number(dataValue.minimum_monthly_commitment) * 30,
                    "day"
                  )
                  .subtract(1, "day")
                  .format("Y-M-D");
                tempPromoDateRanges.availableRange[index] = {
                  start_date: startDate,
                  end_date: endDate,
                };
                tempAdditionalDiscounts[index]["start_date"] =
                  startDate;
                tempAdditionalDiscounts[index]["end_date"] = endDate;
              } else if (dataValue?.one_time) {
                const todayDate = moment().date();
                const addMonths = todayDate > 25 ? 2 : 1;
                /**
                 * @startDate for order creation, the min date for available range will be start of month,
                 * because monthpicker in react-datepicker cannot be picked if the minDate isnt start of month,
                 * but it will be shown the month as available
                 */
                const startDate = id
                  ? moment()
                      .add(addMonths, "month")
                      .startOf("month")
                      .format("Y-M-D")
                  : isLastPaymentLandlordAfterCheckin
                  ? momentLastPaymentToLandlord
                      .startOf("month")
                      .format("Y-M-D")
                  : momentCheckinDate
                      .startOf("month")
                      .format("Y-M-D");
                /**
                 * @endDate for one time since it is using monthpicker,
                 * if the order is close subscription then
                 * we need to make the endDate into the end of the month
                 * since there is an issue when the checkout date is in the start of the month,
                 * the month cannot be selected
                 */
                const endDate = state?.checkout_date
                  ? moment(state?.checkout_date)
                      .endOf("month")
                      .format("Y-M-D")
                  : momentCheckinDate
                      .add(1, "year")
                      .endOf("month")
                      .format("Y-M-D");
                if (isCategoryPromo) {
                  tempPromoDateRanges.availableRange[index] = {
                    start_date: startDate,
                    end_date: endDate,
                  };
                } else {
                  tempDiscountDateRanges.availableRange[index] = {
                    start_date: startDate,
                    end_date: endDate,
                  };
                }
              } else {
                const todayDate = moment().date();
                const addMonths = todayDate > 25 ? 2 : 1;
                const startDate = id
                  ? moment()
                      .add(addMonths, "month")
                      .startOf("month")
                      .format("Y-M-D")
                  : isLastPaymentLandlordAfterCheckin
                  ? momentLastPaymentToLandlord.format("Y-M-D")
                  : momentCheckinDate.format("Y-M-D");
                const endDate = state.checkout_date ?? "";
                if (isCategoryPromo) {
                  tempPromoDateRanges.availableRange[index] = {
                    start_date: startDate,
                    end_date: endDate,
                  };
                } else {
                  tempDiscountDateRanges.availableRange[index] = {
                    start_date: startDate,
                    end_date: endDate,
                  };
                }
              }
              setState((prevState) => ({
                ...prevState,
                t_discount_orders: tempAdditionalDiscounts,
                promoDateRanges: tempPromoDateRanges,
                discountDateRanges: tempDiscountDateRanges,
              }));
            },
          },
          {
            name: "start_date",
            type: "dynamicDateMonthPicker",
            isRequired: true,
            title: "Date Started",
            disabledAtEdit: true,
            enableTime: false,
            dateFormat: "Y-m-d",
            isClearable: false,
            isRenderMonthPicker: getOneTimeStatus,
            getDisabledDate: getDisabledDate,
            generateReadOnly: generateDateReadOnly,
            afterClearDate: afterClearDate,
            handleOnChangeMonthPicker: handleOnChangeMonthPicker,
            excludedDateRange: generateMonthPickerExcludedDateRange,
            afterChange: startDateAfterChange,
          },
          {
            name: "end_date",
            type: "dynamicDateMonthPicker",
            title: "Date Ended",
            isRequired: false,
            enableTime: false,
            dateFormat: "Y-m-d",
            isClearable: false,
            isRenderMonthPicker: getOneTimeStatus,
            getDisabledDate: getDisabledDate,
            generateReadOnly: generateDateReadOnly,
            afterClearDate: afterClearDate,
            handleOnChangeMonthPicker: handleOnChangeMonthPicker,
            afterChange: endDateAfterChange,
          },
          {
            name: "one_time",
            title: "One Time",
            type: "checkbox",
            isDisabled: true,
          },
          {
            name: "remarks",
            title: "Remarks",
            type: "textarea",
            isRequired: false,
            disabledAtEdit: true,
          },
          {
            name: "is_after_order_creation",
            type: "checkbox",
            title: "Flag",
            helperText: "For Devs Purpose",
            readOnly: true,
            dynamicValue: true,
            dataList: state?.[tab.id],
            hideColumnByCSS: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderAdditionalDiscounts);
