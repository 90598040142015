import React, { useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import TabPaneSection from "./TabPaneSection";

import { isMobile } from "react-device-detect";

import utils from "../../../utils";

function TabPane(props) {
  const {
    id,
    btnPreviewSubmit = null,
    children,
    definitions,
    hideAddAnother = false,
    readOnly = false,
    isAbleToDuplicate = false,
    removedDuplicateFields = [],
    replaceValueWhenDuplicate = [],
    isAbleToExtendOrder = false,
    removedUnusedFields = [],
    modifyValueWhenDuplicate = [],
    tab,
    state,
    setState,
    isOptionalHeader = "application/json;charset=UTF-8",
    additionalButtonsBeforeMainButtons = [],
    enableDownloadBtn = false,
    downloadTitleBtn = null,
    downloadLink = null,
    isNotAbleToSave = false,
    beforeSubmit = () => Promise.resolve(),
  } = props;

  const submit = async (e, buttonFlag) => {
    try {
      await beforeSubmit();
      definitions.onSubmit(
        id,
        state,
        setState,
        e,
        props,
        buttonFlag,
        definitions,
        isOptionalHeader
      );
    } catch (error) {
      // Handle error (e.g., user clicked "Cancel" on the SweetAlert dialog)
    }
  };

  useEffect(() => {
    definitions.onInit(id, setState);
  }, []);

  let errorFields = [];

  if (state && state["hasErrors"]) {
    if (definitions?.baseLink.includes("voucher_management")) {
      for (const [key] of Object.entries(state)) {
        if (
          state[key] !== null &&
          key.indexOf("Error") !== -1 &&
          key.indexOf("Tab") === -1 &&
          key !== "hasErrors"
        ) {
          if (key.toLowerCase().includes("brand")) {
            errorFields.push(
              key
                .replace("brand", "Partner")
                .replace("Error", "")
                .replaceAll("_", " ")
            );
          } else if (key.toLowerCase().includes("multiple")) {
            errorFields.push(
              key
                .replace("multiple_use", "maximum_redeem_per_user")
                .replace("Error", "")
                .replaceAll("_", " ")
            );
          } else if (key.toLowerCase().includes("pn_picture")) {
            errorFields.push(
              key
                .replace("pn_picture_original", "push_notif_image")
                .replace("Error", "")
                .replaceAll("_", " ")
            );
          } else {
            errorFields.push(
              key.replace("Error", "").replaceAll("_", " ")
            );
          }
        }
      }
    } else {
      for (const [key] of Object.entries(state)) {
        if (
          state[key] !== null &&
          key.indexOf("Error") !== -1 &&
          key.indexOf("Tab") === -1 &&
          key !== "hasErrors"
        ) {
          errorFields.push(
            key.replace("Error", "").replaceAll("_", " ")
          );
        }
      }
    }
  }

  return (
    <div className="tab-pane show active" id={tab.id}>
      <div className="row">
        <div className="col-12" id="tabPaneElement">
          {state && state["hasTabErrors"] ? (
            <div
              className={
                "alert alert-danger alert-dismissible fade show " +
                (state && state["hasTabErrors"] && state["hasErrors"]
                  ? "mb-1"
                  : "mb-0")
              }
              role="alert"
              style={{ borderRadius: "0px" }}
            >
              <button
                type="button"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    hasTabErrors: false,
                  }))
                }
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {state["customErrorMessage"]
                ? state["customErrorMessage"]
                : "There are error(s) in tabs form inputs, please fix them first before continue!"}
            </div>
          ) : (
            <></>
          )}
          {state && state["hasErrors"] ? (
            <div
              className="alert alert-danger alert-dismissible fade show mb-0"
              role="alert"
              style={{ borderRadius: "0px" }}
            >
              <button
                type="button"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    hasErrors: false,
                  }))
                }
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div
                style={{ marginBottom: "5px" }}
                dangerouslySetInnerHTML={{
                  __html: state["non_field_errorsError"]
                    ? "<b>Error(s)</b>: " +
                      state["non_field_errorsError"]
                    : "There are <b>error(s)</b> in form inputs, please fix them first before continue!",
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    errorFields.length > 0
                      ? "<b>Error fields</b>: " +
                        utils.commons.toTitleCase(
                          errorFields.join(", ")
                        )
                      : "",
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {state && state["info"] ? (
            <div
              className="alert alert-info alert-dismissible fade show mb-0"
              role="alert"
              style={{ borderRadius: "0px" }}
            >
              <button
                type="button"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    info: null,
                  }))
                }
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {state["info"]}
            </div>
          ) : (
            <></>
          )}
        </div>
        {utils.commons.isArray(children) ? (
          children.map((child, i) => {
            return React.cloneElement(child, {
              key: i,
              onSubmit: definitions.onSubmit,
              restAccessCode: definitions.restAccessCode,
              state: state,
              setState: setState,
              frontendPath: definitions.frontendPath,
              baseLink: definitions.baseLink,
              tab: tab,
            });
          })
        ) : children ? (
          React.cloneElement(children, {
            key: "0",
            onSubmit: definitions.onSubmit,
            restAccessCode: definitions.restAccessCode,
            state: state,
            setState: setState,
            frontendPath: definitions.frontendPath,
            baseLink: definitions.baseLink,
            tab: tab,
          })
        ) : (
          <></>
        )}
        <TabPaneSection
          style={{
            padding: "0px 16px 16px",
            borderTop: "1px solid #efefef",
          }}
        >
          <span />
          <div style={{ paddingTop: "16px" }}>
            <Link
              to={
                definitions.frontendPath
                  ? definitions.frontendPath
                  : definitions.baseLink
              }
              className="btn btn-secondary"
              style={{ marginBottom: isMobile ? "10px" : "0px" }}
            >
              {!readOnly ? "Cancel" : "Back"}
            </Link>
            <div className="row float-right">
              {enableDownloadBtn &&
              downloadTitleBtn &&
              downloadLink ? (
                <div>
                  <a
                    key="download-btn"
                    className="btn btn-outline-success mr-1"
                    href={downloadLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {downloadTitleBtn ?? "Download PDF"}
                  </a>
                </div>
              ) : (
                <></>
              )}
              {additionalButtonsBeforeMainButtons.length > 0 && (
                <div>
                  {additionalButtonsBeforeMainButtons.map(
                    (button) => button
                  )}
                </div>
              )}
              {!readOnly && (
                <div>
                  {id && isAbleToExtendOrder ? (
                    <Link
                      to={{
                        pathname: definitions.frontendPath
                          ? definitions.frontendPath + "/add"
                          : definitions.baseLink + "/add",
                      }}
                      onClick={() => {
                        let extendOrderState =
                          utils.commons.cloneState(
                            state,
                            removedUnusedFields,
                            modifyValueWhenDuplicate
                          );
                        delete extendOrderState["id"];
                        localStorage.setItem(
                          "extendOrder",
                          JSON.stringify(extendOrderState)
                        );
                      }}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-success mr-1"
                    >
                      Extend Order
                    </Link>
                  ) : (
                    <></>
                  )}
                  {id && isAbleToDuplicate ? (
                    <a
                      onClick={() => {
                        setState((prevState) => {
                          let newState = { ...prevState };
                          delete newState["id"];

                          for (
                            let i = 0;
                            i <= removedDuplicateFields.length;
                            i++
                          ) {
                            delete newState[
                              removedDuplicateFields[i]
                            ];
                          }

                          for (let key of Object.keys(newState)) {
                            if (key.startsWith("original__")) {
                              delete newState[key];
                            }
                          }

                          for (
                            let j = 0;
                            j <= replaceValueWhenDuplicate.length;
                            j++
                          ) {
                            newState[
                              replaceValueWhenDuplicate[j]?.name
                            ] = replaceValueWhenDuplicate[j]?.value;
                          }

                          newState["info"] =
                            "Data duplicated successfully, please save this duplicated data to persist it to server.";

                          newState["isDuplicate"] = true;
                          return newState;
                        });

                        props.history.push(
                          definitions.frontendPath
                            ? definitions.frontendPath + "/add"
                            : definitions.baseLink + "/add"
                        );

                        window.scrollTo(0, 0);
                      }}
                      className="btn btn-outline-success mr-1"
                      style={{
                        marginBottom: isMobile ? "10px" : "0px",
                      }}
                    >
                      Duplicate
                    </a>
                  ) : (
                    <></>
                  )}

                  {btnPreviewSubmit && btnPreviewSubmit}

                  {!hideAddAnother ? (
                    <a
                      onClick={(e) => {
                        if (isNotAbleToSave) return;
                        submit(e, "Add Another");
                      }}
                      className={`btn ${
                        isNotAbleToSave
                          ? "btn-secondary"
                          : "btn-outline-success"
                      } mr-1`}
                      style={{
                        marginBottom: isMobile ? "10px" : "0px",
                        cursor: isNotAbleToSave
                          ? "not-allowed"
                          : "pointer",
                      }}
                    >
                      Save and add another
                    </a>
                  ) : (
                    <></>
                  )}
                  <a
                    onClick={(e) => {
                      if (isNotAbleToSave) return;
                      submit(e, "Continue Editing");
                    }}
                    className={`btn ${
                      isNotAbleToSave
                        ? "btn-secondary"
                        : "btn-outline-success"
                    } mr-1`}
                    style={{
                      marginBottom: isMobile ? "10px" : "0px",
                      cursor: isNotAbleToSave
                        ? "not-allowed"
                        : "pointer",
                    }}
                  >
                    Save and continue editing
                  </a>
                  <a
                    onClick={(e) => {
                      if (isNotAbleToSave) return;
                      submit(e, "Add");
                    }}
                    className={`btn ${
                      isNotAbleToSave
                        ? "btn-secondary"
                        : "btn-success"
                    } mr-1`}
                    style={{
                      marginBottom: isMobile ? "10px" : "0px",
                      cursor: isNotAbleToSave
                        ? "not-allowed"
                        : "pointer",
                    }}
                  >
                    Save
                  </a>
                </div>
              )}
            </div>
          </div>
        </TabPaneSection>
      </div>
    </div>
  );
}

export default withRouter(TabPane);
